































































import {  Component, Mixins, Prop} from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import { formatDate } from "@/lib/date";
import XDataTable from "@/components/hoc/Table.vue";
import XDropdownWithDatePicker from "@/components/hoc/DropdownWithDatePicker.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import {HistoryOfDevice} from "@/models/accounting-point";

@Component({
  components: { XDataTable, XDropdownWithDatePicker },
  computed: { ...mapState({ accountingPoint: "accountingPoint" }) },
  filters: {
    formatDate(value: string) {
      let d = new Date (value);
      return formatDate(d, "full");
    },
    formatDateMY(value: string) {
      let d = new Date(value);
      return formatDate(d, "monthAndYear");
    }
  },
})
class CounterHistoryTable extends Mixins(AppApiMixin) {
  @Prop({ default: false }) readonly setDefaultDate!: boolean;
  [x: string]: any;
  dates: string[] = [];

  headers = [
    { text: "Дата", value: "date", /*width: "15%"*/ },
    { text: "Зона суток", value: "dayZone", /*width: "20%"*/ },
    { text: "Показание", value: "indicate", /*width: "5%" */},
    { text: "За", value: "dateZa", /*width: "5%" */},
    { text: "Расход", value: "value", /*width: "5%",*/ },
    { text: "Итоговый расход (с учетом коэффициента трансформации)", value: "total", width: "27%" },
    { text: "Тип ввода", value: "type", /*width: "10%"*/ },
  ];

  get icons() {
    if (this.accountingPoint.deviceHistory) {
      return this.accountingPoint.deviceHistory.map((item: HistoryOfDevice) => {
        switch (item.зона.toLowerCase()) {
          case 'день':
            return 'mdi-white-balance-sunny';
          case 'ночь':
            return 'mdi-brightness-2';
          case 'ппик':
            return 'mdi-call-missed';
          case 'пик':
            return 'mdi-mall-made';
        }
      })
    } else return []
  }

  /**
   * Вычисляемое свойство, значением которого является объект. Ключами такого
   * объекта являются значения свойств value объекта headers, а значениями - строки,
   * использующиеся в качестве css-классов td-элементов таблицы, что позволяет
   * определять стили для отдельного столбца. Например, для ячейки, расположенной
   * в столбце с заголовком "к оплате", будет создана следующая строка:
   * "data-table__td data-table__td_header_sum-to-paid" (см. vue-dev-tools).
   */
  public get tdClassesByHeaders() {
    const headerValues = this.headers.map(({ value }) => value);
    const tdClasses: { [headerValue: string]: string } = {};

    return headerValues.reduce((acc, headerValue) => {
      const headerId = headerValue.replace("document", "");
      let counter = 0;

      const headerIdKebabCase = headerId.replace(/[A-Z]/g, (match) => {
        const result = match.toLowerCase();
        return counter++ ? `-${result}` : result;
      });

      return {
        ...acc,
        [headerValue]: `data-table__td data-table__td_header_${headerIdKebabCase}`,
      };
    }, tdClasses);
  }

  public get items(): Array<string[]> {
    const requiredKeys = ["дата","зона","показание","за","расход","итоговый расход","@тип ввода"];
    let pointValues: Array<string[]> = [];

    if (this.accountingPoint.deviceHistory) {
      pointValues = this.accountingPoint.deviceHistory.map(
        (param: { [x: string]: any }) => {
          param['за'] = param['дата'];
          param['@тип ввода'] = (param['@тип ввода'] === 'ДС')
            ? 'По замещающей информации'
            : param['@тип ввода'];
          const historyValues = requiredKeys.map((key) => param[key]);
          return [...historyValues ];
        }
      );
    }
    // если устанволен период показаний, фильтруем записи по дате
    if(this.dates[0] && this.dates[1]) {
      let date = new Date(this.dates[1]);
      date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return pointValues.filter(value => Date.parse(value[0]) >= Date.parse(this.dates[0]) && Date.parse(value[0]) <= Date.parse(date.toISOString()))
    }
    return pointValues;

  }

}

export default CounterHistoryTable;
