import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "@/store/store";

import {
  AppApiResponse,
  CompanyAsNewUser,
  ConsumptionAsReqBody,
  Credentials,
  DocumentsAsReqBody,
  ContractDocumentsAsReqBody,
  AppealsByContractAsReqBody,
  HourlyPointAsReqBody,
  NewEmailProps,
  PasswordsAsReqBody,
  ReportAsReqBody,
  SetOfDocumentsAsReqBody,
  GetContractDocumentAsReqBody,
  Turnovers,
  TurnoversDetails,
  AppealHistoryRefreshReq,
  AppealThemesAsReqBody,
  AppealHistoryFilesRefreshReq,
  AppealHistoryFileInfoRefreshReq,
  AppealCustomFieldsReq,
  NotificationsByContractAsReqBody,
} from "@/models/app-api";

import User from "@/models/user";
import Environment from "@/models/environment";

import {
  Contract,
  ContractBalance,
  DocumentsByContract,
  ContractDocumentsEDOByContract,
  ContractDocumentsByContract,
  ExtendedContract,
  SetOfDocuments,
} from "@/models/contract";

import {
  Organization,
  OrganizationAgent,
  OrganizationConsumption,
  OrganizationContact,
  OrganizationPaymentAccount,
  OrganizationReport,
  OrganizationTurnovers,
  OrganizationTurnoversDetails,
} from "@/models/organization";

import {
  AccountingPoint,
  MeasuringComplexByAccountingPoint,
  OtherEquipmentByAccountingPoint,
  ParamsByAccountingPoint,
  PlanServicesByAccountingPoint,
  PlanValuesByAccountingPoint,
} from "@/models/accounting-point";

import { AskueObjects, HourlyPoint } from "@/models/hourly-consumption";
import {
  AppealsByContract,
  AppealThemes,
  AppealThemeTypes,
  AppealChatMessages,
  AppealChatFiles,
  AppealHistoryFileInfo,
  AppealCustomFields
} from "@/models/appeals";

import { Indication } from "@/models/indications";
import { NotificationsByContract } from "@/models/notifications";
import AppConfig from "@/models/app-config";

interface AppApiX {
  // Интерфейс содержит перечень методов, описывающих запросы к бекенду, а
  // их реализация происходит в рамках класса AppApi.

  // Конфиг:
  fetchAppConfig(): Promise<{ result: boolean; data: AppConfig }>;

  // Переменные окружения:
  fetchEnvironment(): Promise<{ result: boolean; data: Environment }>;

  // Авторизация, регистрация, логаут:
  signIn(credentials: Credentials): Promise<User>;

  signUp(reqBody: FormData): Promise<{ result: boolean }>;

  signOut(): Promise<{ result: boolean; logout: boolean }>;

  // Изменение учетных данных:
  changePassword(
    passwordProps: PasswordsAsReqBody
  ): Promise<{ result: boolean; message: string[] }>;

  changeEmail(email: string): Promise<{ result: boolean; code?: number; data?: string }>;

  confirmEmailChange(
    newEmailProps: NewEmailProps
  ): Promise<{ result: boolean; message: string }>;

  recoveryPassword(email: string): Promise<{ result: boolean; message: string }>;

  // Договоры:
  fetchContracts(
    userId: number
  ): Promise<{ result: boolean; data: Contract[] }>;

  fetchContractsBalances(
    contractId: string
  ): Promise<{ result: boolean; data: ContractBalance[] }>;

  chooseContract(
    contractId: number
  ): Promise<{ result: boolean; data: ExtendedContract }>;

  removeContract(
    contractId: number
  ): Promise<{ result: boolean; data: string[] }>;

  addContract(
    newContract: CompanyAsNewUser
  ): Promise<{ result: boolean; data: string }>;

  // Точки учета:
  fetchAccountingPoints(
    contractId: number
  ): Promise<{ result: boolean; data: AccountingPoint[] }>;

  fetchPointMeasuringComplex(
    pointId: number
  ): Promise<{ result: boolean; data: MeasuringComplexByAccountingPoint[] }>;

  fetchPointParams(
    pointId: number
  ): Promise<{ result: boolean; data: ParamsByAccountingPoint[] }>;

  // Организация:
  fetchOrganization(
    companyId: number
  ): Promise<{ result: boolean; data: Organization[] }>;

  fetchOrganizationContacts(
    companyId: number
  ): Promise<{ result: true; data: OrganizationContact[] }>;

  fetchOrganizationPaymentAccounts(
    companyId: number
  ): Promise<{ result: boolean; data: OrganizationPaymentAccount[] }>;

  fetchOrganizationAgents(
    companyId: number
  ): Promise<{ result: boolean; data: OrganizationAgent[] }>;

  // Обороты:
  fetchOrganizationTurnovers(
    turnovers: Turnovers
  ): Promise<{ result: boolean; data: OrganizationTurnovers[] }>;

  fetchOrganizationTurnoversDetails(
    turnovers: TurnoversDetails
  ): Promise<{ result: boolean; data: OrganizationTurnoversDetails[] }>;

  fetchOrganizationReports(
    contractId: number
  ): Promise<{ result: boolean; data: OrganizationReport[] }>;

  getReportByContract(reportProps: ReportAsReqBody): Promise<AxiosResponse>;

  fetchAnalysts(): Promise<AxiosResponse>;

  fetchServicesByContract(
    contractId: number
  ): Promise<{ result: boolean; data: SetOfDocuments[] }>;

  fetchConsumptionList(
    consumptionProps: ConsumptionAsReqBody
  ): Promise<{ result: boolean; data: OrganizationConsumption[] }>;

  // Документы:
  fetchDocumentsByContract(
    docs: DocumentsAsReqBody
  ): Promise<{ result: true; data: DocumentsByContract[] }>;

  fetchSetOfDocumentsByContract(
      setOfDocs: SetOfDocumentsAsReqBody
  ): Promise<AxiosResponse>;

  // Документы договора:
  fetchContractDocumentsByContract(
    docs: ContractDocumentsAsReqBody
  ): Promise<{ result: true; data: ContractDocumentsByContract[] }>;

  getContractDocumentByContract(reqBody: ContractDocumentsAsReqBody): Promise<AxiosResponse>;

  getContractDocumentEDOByContract(reqBody: ContractDocumentsAsReqBody): Promise<AxiosResponse>;

  //Список объектов для почасового потребления
  fetchAskueObjects(
    contractId: number
  ): Promise<{ result: boolean; data: AskueObjects[] }>;

  //обращения получение всей пачки
  fetchAppealsByContract(
    appeals: AppealsByContractAsReqBody
  ): Promise<{ result: true; data: AppealsByContract[] }>;

  //получение тем для обращений
/*  fetchAppealThemes(
    appealThemes: AppealThemesAsReqBody
  ): Promise<{ result: true; data: AppealThemes[] }>;*/

  fetchAppealThemes(
    contractId: number
  ): Promise<{ result: true; data: AppealThemes[] }>;


  //получение вопросв для тем обращений
  fetchAppealThemeTypes(
    themeId: number,
    contractId: number
  ): Promise<{ result: true; data: AppealThemeTypes[] }>;

  // история по одному обращению
/*  fetchAppealHistory(
    contractId: number,
    appealId: number
  ): Promise<{ result: true; data: AppealChatMessages[] }>;*/

  fetchAppealHistory(
    reqBody: AppealHistoryRefreshReq
  ): Promise<{ result: true; data: AppealChatMessages[] }>;

  fetchAppealFiles(
    reqBody: AppealHistoryFilesRefreshReq
  ): Promise<{ result: true; data: AppealChatFiles[] }>;

  fetchHistoryAppealFileInfo(
    reqBody: AppealHistoryFileInfoRefreshReq
  ): Promise<{ result: true; data: AppealHistoryFileInfo[] }>;

  // получение уведомлений
  fetchNotificationsByContract(
      appeals: NotificationsByContractAsReqBody
  ): Promise<{ result: true; data: NotificationsByContract[] }>;
}

class AppApi implements AppApiX {
  private axios;

  constructor(baseURL: string) {
    this.axios = axios.create({
      baseURL,
      withCredentials: false
    });

    this.useRequestInterceptor();
    this.useResponseInterceptor();
  }

  private addContractIdToReqBody(config: AxiosRequestConfig) {
    const URL_PATTERNS_WITH_CONTRACT_ID = [
      /^\/hourly_consumption/,
      /^\/metering_device_/,
      /^\/metering_point_/,
      /^\/notifications/,
      /^\/notificationFiles/,
      /^\/notificationRead/,
    ];

    const { data, url = "" } = config;

    if (URL_PATTERNS_WITH_CONTRACT_ID.some(pattern => pattern.test(url))) {
      data.contractId = store.getters["contract/id"];
    }
  }

  // Определить общий перехватчик запросов.
  private useRequestInterceptor() {
    this.axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const { data, headers, url = "" } = config;
        const xApiToken = localStorage.getItem("xApiToken");

        if (xApiToken) {
          headers["x-api-token"] = xApiToken;
        }

        this.addContractIdToReqBody(config);

        // Добавить responseType в конфигурацию axios, что позволяет обеспечить
        // загрузку файла на компьютер пользователя.
        const URLsWithBlob = [
          "/hourly_consumption/download_profile",
          "/metering_device/get_profile",
          "/metering_device/get_log",
          "/reports",
          "/set_of_documents",
          "/contract_documents/document",
          "/appealHistoryFileDownload",
          "/appeal/get_file",
          "/auth/get_pattern_doc",
          "/contract_documents_edo/download",
          "/notificationFiles",
        ];

        if (URLsWithBlob.includes(url) && data.download) {
          config.responseType = "blob";
        }

        return config;
      },
      error => Promise.reject(error)
    );
  }

  // Определить общий перехватчик ответов.
  private useResponseInterceptor() {
    this.axios.interceptors.response.use(
      response => response,
      reason => {
        const currentURL = reason.config.url;
        // Регулярное выражение для маршрутов, которые используют собственные
        // компоненты для отображения ошибок.
        const routeWithOwnErrorPattern = /^(\/auth|\/contract\/new$)/;

        if (!routeWithOwnErrorPattern.test(currentURL)) {
          const { data, status } = reason.response;

          if (status === 401 && data.logout) {
            return store.commit("auth/setLoggedIn", false);
          }

          store.commit("error/setMessage", data.message);
          store.commit("error/setShow", true);
        }

        return Promise.reject(reason);
      }
    );
  }

  // Определить общий обработчик запросов к серверу.
  private async handleAxiosResponse(
    request: Promise<AxiosResponse<AppApiResponse | any>>
  ) {
    try {
      return (await request).data;
    } catch (reason) {
      return Promise.reject(this.onReject(reason));
    }
  }

  /**
   * Извлечь конфиг с общими данными для фронта и бэка
   * @returns ответ сервера.
   */
  public fetchAppConfig(): Promise<{ result: boolean; data: AppConfig }> {
    return this.handleAxiosResponse(this.axios.post("/config"));
  }

  /**
   * Извлечь переменные окружения, определяющие функционал приложения.
   * @returns ответ сервера.
   */
  public fetchEnvironment(): Promise<{ result: boolean; data: Environment }> {
    return this.handleAxiosResponse(this.axios.post("/constants"));
  }

  /**
   * Авторизовать пользователя в приложении.
   * @param credentials учетные данные пользователя.
   * @returns ответ сервера.
   */
  public signIn(credentials: Credentials): Promise<User> {
    return this.handleAxiosResponse(
      this.axios.post("/auth/login", credentials)
    );
  }

  /**
   * Зарегистрировать пользователя в приложении.
   * @param reqBody форма с данными.
   * @returns ответ сервера.
   */
  public signUp(reqBody: FormData): Promise<{ result: boolean }> {
    return this.handleAxiosResponse(
      this.axios.post("/auth/registration", reqBody)
    );
  }

  /**
   * Совершить логаут пользователя из приложения.
   * @returns ответ сервера.
   */
  public signOut(): Promise<{ result: boolean; logout: boolean }> {
    return this.handleAxiosResponse(this.axios.post("/auth/logout"));
  }

  /**
   * Изменить пароль пользователя ЛК.
   * @param passwordProps объект, содержащий значения, необходимые для изменения пароля.
   * @returns ответ сервера.
   */
  public changePassword(
    passwordProps: PasswordsAsReqBody
  ): Promise<{ result: boolean; message: string[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/change_password", passwordProps)
    );
  }

  /**
   * Изменить адрес электронной почты пользователя ЛК.
   * @param email адрес новой электронной почты.
   * @returns ответ сервера.
   */
  changeEmail(email: string): Promise<{ result: boolean; code?: number; data?: string }> {
    return this.handleAxiosResponse(
      this.axios.post("/change_email/send_new_mail", { email })
    );
  }

  /**
   * Подтвердить изменение адреса электронной почты пользователя ЛК.
   * @param newEmailProps объект, содержащий значения, необходимые для изменения
   * адреса электронной почты.
   * @returns ответ сервера.
   */
  confirmEmailChange(
    newEmailProps: NewEmailProps
  ): Promise<{ result: boolean; message: string }> {
    return this.handleAxiosResponse(
      this.axios.post("/change_email/send_new_mail/confirm", newEmailProps)
    );
  }

  /**
   * Запросить восстановление пароля для пользователя
   * @param login логин пользователя.
   * @returns ответ сервера.
   */
  recoveryPassword(login: string): Promise<{ result: boolean; message: string }> {
    return this.handleAxiosResponse(
        this.axios.post("/auth/recovery_password", { login })
    );
  }

  /**
   * Запросить восстановление пароля для пользователя
   * @param login логин пользователя.
   * @param guid guid для проверки правильности электронной почты
   * @returns ответ сервера.
   */
  recoveryPasswordConfirm(login: string, guid: string): Promise<{ result: boolean; message: string }> {
    return this.handleAxiosResponse(
        this.axios.post("/auth/recovery_password_confirm", { login, guid })
    );
  }

  /**
   * Извлечь список договоров.
   * @param userId идентификатор пользователя.
   * @returns ответ сервера.
   */
  public fetchContracts(
    userId: number
  ): Promise<{ result: boolean; data: Contract[] }> {
    return this.handleAxiosResponse(this.axios.post("/contracts", { userId }));
  }

  /**
   * Извлечь данные о значениях сальдо и предоплаты по договору (-ам).
   * @param contractId строка, содержащая перечень идентификаторов договоров,
   * разделенных запятой.
   * @returns ответ сервера.
   */
  public fetchContractsBalances(
    contractId: string
  ): Promise<{ result: boolean; data: ContractBalance[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/contracts/balance", { contractId })
    );
  }

  /**
   * Выбрать определенный договор, используя его идентификатор.
   * @param contractId идентификатор договора.
   * @returns ответ сервера.
   */
  public chooseContract(
    contractId: number
  ): Promise<{ result: boolean; data: ExtendedContract }> {
    return this.handleAxiosResponse(
      this.axios.post("/contract", { contractId })
    );
  }

  /**
   * Удалить определенный договор, используя его идентификатор.
   * @param contractId идентификатор договора.
   * @returns ответ сервера.
   */
  public removeContract(
    contractId: number
  ): Promise<{ result: boolean; data: string[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/contract/del", { contractId })
    );
  }

  /**
   * Добавить новый договор.
   * @param newContract данные нового договора.
   * @returns ответ сервера.
   */
  public addContract(
    newContract: CompanyAsNewUser
  ): Promise<{ result: boolean; data: string }> {
    return this.handleAxiosResponse(
      this.axios.post("/contract/new", newContract)
    );
  }

  /**
   * Извлечь список точек учета при выборе договора.
   * @param contractId идентификатор договора.
   * @returns ответ сервера.
   */
  public fetchAccountingPoints(
    contractId: number
  ): Promise<{ result: boolean; data: AccountingPoint[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/metering_points", { contractId })
    );
  }

  /**
   * Извлечь список точек учета для истории показаний при выборе договора.
   * @param contractId идентификатор договора.
   * @returns ответ сервера.
   */
  public fetchAccountingHistoryPoints(
    contractId: number
  ): Promise<{ result: boolean; data: AccountingPoint[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/metering_device/history", { contractId })
    );
  }

  /**
   * Отправить новое показание счетчика.
   * @param meteringPointId Идентификатор точки учета
   * @param counterId Идентификатор прибора учета
   * @param indications Массив показаний по всем тарифам
   * @returns ответ сервера.
   */
  public sendCountersNewValue(
      meteringPointId: number,
      counterId: number,
      indications: Array<Indication>,
  ): Promise<AxiosResponse> {
    return this.axios.post("/metering_device_add_readings", { meteringPointId, counterId, indications });
  }

  /**
   * Извлечь список точек учета при подаче показаний.
   * @param contractId идентификатор договора.
   * @returns ответ сервера.
   */
  public fetchAccountingPointsPU(contractId: number): Promise<AxiosResponse> {
    return this.axios.post("/metering_point_pu", { contractId });
  }

  /**
   * Извлечь сведения, представляющие измерительный комплекс точки учета.
   * @param pointId идентификатор точки учета.
   * @returns ответ сервера.
   */
  public fetchPointMeasuringComplex(
    pointId: number
  ): Promise<{ result: boolean; data: MeasuringComplexByAccountingPoint[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/metering_point_measuring_complex", {
        meteringPointId: pointId
      })
    );
  }

  /**
   * Извлечь сведения, представляющие прочее оборудоваие на точке учета.
   * @param pointId идентификатор точки учета.
   * @returns ответ сервера.
   */
  public fetchPointOtherEquipment(
    pointId: number
  ): Promise<{ result: boolean; data: OtherEquipmentByAccountingPoint[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/metering_point_other_equipment", {
        meteringPointId: pointId
      })
    );
  }

  /**
   * Извлечь сведения, представляющие параметры точки учета.
   * @param pointId идентификатор точки учета.
   * @param dateFrom дата начала периода.
   * @param dateTo дата конца периода.
   * @returns ответ сервера.
   */
  public fetchPointHistory(
    pointId: number,
    dateFrom: string,
    dateTo: string
  ): Promise<AxiosResponse> {
    return this.axios.post("/metering_point_values", {
      meteringPointId: pointId,
      filter: {
        датНач: dateFrom,
        датКнц: dateTo
      }
    });
  }

  /**
   * Извлечь сведения, представляющие параметры точки учета.
   * @param pointId идентификатор точки учета.
   * @param contractId идентификатор договора.
   * @returns ответ сервера.
   */
  public fetchPUHistory(
    pointId: number,
    contractId: number
  ): Promise<AxiosResponse> {
    return this.axios.post("/metering_device/history/counters", {
      pointId,
      contractId
    });
  }

  /**
   * Извлечь сведения о почасовом потреблении определенного объекта или ТУ.
   * @param reqBody объект, представляющий тело запроса.
   * @returns ответ сервера.
   */
  public fetchHourlyPoint(
    reqBody: HourlyPointAsReqBody
  ): Promise<{ result: boolean; data: HourlyPoint[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/hourly_consumption", reqBody)
    );
  }

  /**
   * Скачать шаблон документа, необходимый для передачи сведений о почасовом
   * потреблении по объекту или ТУ.
   * @param mode число, указывающее на тип шаблона документа.
   * @param month месяц, на который будет подготовлен шаблон документа.
   * @returns ответ сервера.
   */
  public downloadConsumptionDocument(mode: 0 | 1 | 2, month: string) {
    const reqBody = {
      mode,
      month,
      // Использовать download: true, чтобы перехватить запрос и обновить конфигурацию
      // axios, добавив responseType: blob.
      download: true
    };

    return this.axios.post("/hourly_consumption/download_profile", reqBody);
  }

  /**
   * Загрузить шаблон документа, необходимый для передачи сведений о почасовом
   * потреблении по объекту или ТУ, на сервер.
   * @param formData объект, представляющий тело запроса в виде FormData.
   * @returns ответ сервера.
   */
  public uploadConsumptionDocument(formData: FormData) {
    const reqConfig = {
      headers: { "Content-Type": "multipart/form-data" }
    };

    return this.handleAxiosResponse(
      this.axios.post("/hourly_consumption/upload_profile", formData, reqConfig)
    );
  }

  /**
   * Извлечь сведения о почасовом потреблении
   * @param contractId,
   */
  public fetchAskueObjects(
    contractId: number
  ): Promise<{ result: boolean; data: AskueObjects[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/objects_askue", { contractId })
    );
  }

  /**
   * Получить приборы учета по номеру договора
   * @param contractId,
   */
  public fetchContractPoints(
    contractId: number
  ): Promise<{ result: boolean; data: [] }> {
    return this.handleAxiosResponse(
      this.axios.post("/contract_points", { contractId })
    );
  }

  /**
   * Извлечь сведения, представляющие историю показаний по точке учета.
   * @param pointId идентификатор точки учета.
   * @returns ответ сервера.
   */
  public fetchPointParams(
    pointId: number
  ): Promise<{ result: boolean; data: ParamsByAccountingPoint[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/metering_point_params", {
        meteringPointId: pointId
      })
    );
  }

  /**
   * Скачать шаблон для массового ввода показаний.
   * @param reqBody - объект содержащий id договора
   * @returns ответ сервера.
   */
  public fetchMassValuesInputTemplateByContract(reqBody: {
    contractId: number;
    download: boolean;
  }) {
    return this.axios.post("/metering_device/get_profile", reqBody);
  }

  /**
   * Проверить заполненный шаблон для массового ввода показаний.
   * @param reqBody - объект содержащий id договора
   * @returns ответ сервера.
   */
  public checkMassValuesInputTemplateByContract(reqBody: FormData) {
    return this.axios.post("/metering_device/check", reqBody, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  /**
   * Загрузить заполненный шаблон для массового ввода показаний.
   * @param reqBody - объект содержащий id договора
   * @returns ответ сервера.
   */
  public sendMassValuesInputTemplateByContract(reqBody: FormData) {
    return this.axios.post("/metering_device/upload", reqBody, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  /**
   * Скачать файл с результатами массового ввода показаний.
   * @param reqBody - объект с данными для получения файла
   * @returns ответ сервера.
   */
  public fetchMassValuesInputResultFile(reqBody: {
    fileId: string;
    fileName: string;
    fileUrl: string;
    download: boolean;
  }) {
    return this.axios.post("/metering_device/get_log", reqBody);
  }


  public fetchAppealHistoryFileDownload(reqBody: {
    fileUrl: string;
    fileId: string;
    download: boolean;
  }) {
    return this.axios.post("/appeal/get_file", reqBody);
  }


  /**
   * Извлечь сведения, представляющие услуги для плана по ТУ.
   * @param pointId идентификатор точки учета.
   * @returns ответ сервера.
   */
  public fetchPointPlanServices(
    pointId: number
  ): Promise<{ result: boolean; data: PlanServicesByAccountingPoint[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/metering_point_plan_services", {
        meteringPointId: pointId
      })
    );
  }

  /**
   * Отправить плановые объемы по точке учета.
   * @param meteringPointId идентификатор точки учета.
   * @param service идентификатор услуги.
   * @param year год.
   * @param values плановые объемы по услуге на выбранный год.
   * @returns ответ сервера.
   */
  public sendPlanValues(
    meteringPointId: number,
    service: number,
    year: number,
    values: { [x: string]: number }
  ): Promise<AxiosResponse> {
    return this.axios.post("/metering_point_plan_values/set", {
      meteringPointId,
      service,
      year,
      m: values
    });
  }

  /**
   * Извлечь сведения, представляющие плановые объемы по ТУ.
   * @param pointId идентификатор точки учета.
   * @returns ответ сервера.
   */
  public fetchPointPlanValues(
    pointId: number
  ): Promise<{ result: boolean; data: PlanValuesByAccountingPoint[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/metering_point_plan_values", {
        meteringPointId: pointId
      })
    );
  }

  /**
   * Извлечь основную информацию о ЮЛ.
   * @param organizationId идентификатор ЮЛ.
   * @returns ответ сервера.
   */
  public fetchOrganization(
    organizationId: number
  ): Promise<{ result: boolean; data: Organization[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/organization", { organizationId })
    );
  }

  /**
   * Извлечь контактную информацию о ЮЛ.
   * @param organizationId идентификатор ЮЛ.
   * @returns ответ сервера.
   */
  public fetchOrganizationContacts(
    organizationId: number
  ): Promise<{ result: true; data: OrganizationContact[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/organization_contacts", { organizationId })
    );
  }

  /**
   * Извлечь платежную информацию о ЮЛ.
   * @param organizationId идентификатор ЮЛ.
   * @returns ответ сервера.
   */
  public fetchOrganizationPaymentAccounts(
    organizationId: number
  ): Promise<{ result: boolean; data: OrganizationPaymentAccount[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/organization_checking_accounts", {
        organizationId
      })
    );
  }

  /**
   * Извлечь информацию о представителях ЮЛ.
   * @param organizationId идентификатор ЮЛ.
   * @returns ответ сервера.
   */
  public fetchOrganizationAgents(
    organizationId: number
  ): Promise<{ result: boolean; data: OrganizationAgent[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/organization_representatives", {
        organizationId
      })
    );
  }

  /**
   * Извлечь информацию об оборотах ЮЛ.
   * @param reqBody объект, свойства которого используются для выборки данных
   * об оборотах ЮЛ.
   * @returns ответ сервера.
   */
  public fetchOrganizationTurnovers(
    reqBody: Turnovers
  ): Promise<{ result: boolean; data: OrganizationTurnovers[] }> {
    return this.handleAxiosResponse(this.axios.post("/turnovers", reqBody));
  }

  /**
   * Извлечь информацию об оборотах ЮЛ за определенный месяц.
   * @param reqBody объект, свойства которого используются для выборки данных
   * об оборотах ЮЛ за определенный месяц.
   * @returns ответ сервера.
   */
  public fetchOrganizationTurnoversDetails(
    reqBody: TurnoversDetails
  ): Promise<{ result: boolean; data: OrganizationTurnoversDetails[] }> {
    return this.handleAxiosResponse(this.axios.post("/balance", reqBody));
  }

  /**
   * Извлечение данных о доступном перечне отчетов определенному ЮЛ.
   * @param contractId идентификатор договора.
   * @returns ответ сервера.
   */
  public fetchOrganizationReports(
    contractId: number
  ): Promise<{ result: boolean; data: OrganizationReport[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/reports", { contractId })
    );
  }

  /**
   * Получить отчет в форме pdf-файла или вложения к электронному письму.
   * @param reqBody объект, свойства которого используются для формирования отчета.
   * @returns ответ сервера.
   */
  public getReportByContract(reqBody: ReportAsReqBody): Promise<AxiosResponse> {
    return this.axios.post("/reports", reqBody);
  }

  /**
   * Извлечь информацию о документообороте по договору.SV
   * @param reqBody объект, свойства которого используются для выборки данных
   * о документах по определенному договору.
   * @returns ответ сервера.
   */
  public fetchDocumentsByContract(
    reqBody: DocumentsAsReqBody
  ): Promise<{ result: true; data: DocumentsByContract[] }> {
    return this.handleAxiosResponse(this.axios.post("/documents", reqBody));
  }

  /**
   * Извлечь информацию о документах договора по договорам
   * @param reqBody объект, свойства которого используются для выборки данных
   * о документах по определенному договору.
   * @returns ответ сервера.
   */
  public fetchContractDocumentsByContract(
      reqBody: ContractDocumentsAsReqBody
  ): Promise<{ result: true; data: ContractDocumentsByContract[] }> {
    return this.handleAxiosResponse(this.axios.post("/contract_documents", reqBody));
  }

  /**
   * Извлечь информацию о документах договора по договорам
   * @param reqBody объект, свойства которого используются для выборки данных
   * о документах по определенному договору.
   * @returns ответ сервера.
   */
  public fetchContractDocumentsEDOByContract(
    reqBody: ContractDocumentsAsReqBody
  ): Promise<{ result: true; data: ContractDocumentsEDOByContract[] }> {
    return this.handleAxiosResponse(this.axios.post("/contract_documents_edo", reqBody));
  }

  /**
   * Установить статус документа ЭДО.
   * @returns ответ сервера.
   * @param reqBody объект, свойства которого используются для выборки данных
   * о документах по определенному договору.
   */
  public setDocumentStatusEDO(
    reqBody: ContractDocumentsAsReqBody
  ): Promise<{ result: boolean; data: string }> {
    return this.handleAxiosResponse(
      this.axios.post("/contract_documents_edo/status", reqBody)
    );
  }

  /**
   * Получить отчет в форме pdf-файла или вложения к электронному письму.
   * @param reqBody объект, свойства которого используются для формирования отчета.
   * @returns ответ сервера.
   */
  public getContractDocumentByContract(reqBody: GetContractDocumentAsReqBody): Promise<AxiosResponse> {
    return this.axios.post("/contract_documents/document", reqBody);
  }

  /**
   * Получить данные документа в виде zip-файла или вложения к электронному письму.
   * @param reqBody объект, свойства которого используются для запроса файла.
   * @returns ответ сервера.
   */
  public getContractDocumentEDOByContract(reqBody: GetContractDocumentAsReqBody): Promise<AxiosResponse> {
    return this.axios.post("contract_documents_edo/download", reqBody);
  }

  /**
   * Извлечь список аналитик.
   * @returns ответ сервера.
   */
  public fetchAnalysts(): Promise<AxiosResponse> {
    return this.axios.post("/analysts");
  }

  /**
   * Отправить обращение.
   * @returns ответ сервера.
   */
  public sendAppeal(reqBody: FormData): Promise<AxiosResponse> {
    return this.axios.post("/appeal/send", reqBody);
  }

  /**
   * Отправить сообщение в обращении.
   * @returns ответ сервера.
   */
  public sendAppealMessage(reqBody: FormData): Promise<AxiosResponse> {
    return this.axios.post("/appeal/sendNewMessage", reqBody);
  }

  /**
   * Отправить сброс поля "непрочитано" с обращения .
   * @returns ответ сервера.
   */
  public sendUnreadReset(appealId: number): Promise<AxiosResponse> {
    return this.axios.post("/appeal/resetUnread", {appealId});
  }

  /**
  * Получить все обращения по контракту
  * @returns ответ сервера
  * */
  public fetchAppealsByContract(
    reqBody: AppealsByContractAsReqBody
  ): Promise<{ result: true; data: AppealsByContract[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/appeals",  reqBody )
    );
  }

  /**
   * Получить темы обращений
  * */
/*  public fetchAppealThemes(
    reqBody: AppealThemesAsReqBody
  ): Promise<{ result: true; data: AppealThemes[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/appealThemes", reqBody )
    );
  }*/
  public fetchAppealThemes(
    contractId: number
  ): Promise<{ result: true; data: AppealThemes[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/appealThemes", {contractId} )
    );
  }

  /**
   * Получить вопросы для тем обращений
   */
  public fetchAppealThemeTypes(
    themeId: number,
    contractId: number
  ): Promise<{ result: true; data: AppealThemeTypes[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/appealThemeTypes", { themeId, contractId } )
    );
  }

  /**
   * Запрос кастомных полей для обращения
   *
   * @return ответ сервера
   * @param reqBody
   */
  public fetchAppealCustomFields(
    reqBody: AppealCustomFieldsReq
  ): Promise<{ result: true; data: AppealCustomFields[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/appealCustomFields", reqBody )
    );
  }

  /**
   * Получить историю сообщений по обращению
   */
/*  public fetchAppealHistory(
    appealId: number,
    contractId: number,
  ): Promise<{ result: true; data: AppealChatMessages[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/appealHistory", {appealId,contractId} )
    );
  }*/

  public fetchAppealHistory(
    reqBody: AppealHistoryRefreshReq
  ): Promise<{ result: true; data: AppealChatMessages[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/appealHistory", reqBody )
    );
  }
  public fetchAppealFiles(
    reqBody: AppealHistoryFilesRefreshReq
  ): Promise<{ result: true; data: AppealChatFiles[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/appealHistoryFiles", reqBody )
    );
  }

  public fetchHistoryAppealFileInfo(
    reqBody: AppealHistoryFileInfoRefreshReq
  ): Promise<{result: true; data: AppealHistoryFileInfo[]}> {
    return this.handleAxiosResponse(
      this.axios.post("/appealHistoryClickFileInfo", reqBody )
    );
  }

  public fetchNotificationsByContract(): Promise<{ result: true; data: NotificationsByContract[] }> {
    return this.handleAxiosResponse(
        this.axios.post("/notifications", {})
    );
  }

  public getNotificationFilesByContract(notificationId: number) {
    return this.axios.post("/notificationFiles", { notificationId, download: true });
  }

  public readNotificationByContract(notificationId: number): Promise<AxiosResponse> {
    return this.handleAxiosResponse(
        this.axios.post("/notificationRead", { notificationId })
    );
  }

  /**
   * Извлечь список услуг, предусмотренных выбранным договором.
   * @param contractId идентификатор договора.
   * @returns ответ сервера.
   */
  public fetchServicesByContract(
    contractId: number
  ): Promise<{ result: boolean; data: SetOfDocuments[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/consumption_analysis/services", { contractId })
    );
  }

  /**
   * Извлечь информацию об анализе потребления ЮЛ.
   * @param reqBody объект, свойства которого используются для выборки данных
   * об анализе потребления ЮЛ.
   * @returns ответ сервера.
   */
  public fetchConsumptionList(
    reqBody: ConsumptionAsReqBody
  ): Promise<{ result: boolean; data: OrganizationConsumption[] }> {
    return this.handleAxiosResponse(
      this.axios.post("/consumption_analysis", reqBody)
    );
  }

  /**
   * Извлечь информацию о данных в каналах ТУ.
   * @param reqBody объект, свойства которого используются для выборки данных
   * об анализе потребления ЮЛ.
   * @returns ответ сервера.
   */
  public fetchChannelsList(
    reqBody: {}
  ): Promise<{ result: boolean; data: [] }> {
    return this.handleAxiosResponse(
      this.axios.post("/channel_analysis", reqBody)
    );
  }

  public fetchTableAnalysis(
    reqBody: {}
  ): Promise<{ result: boolean; data: [] }> {
    return this.handleAxiosResponse(
      this.axios.post("/table_analysis", reqBody)
    );
  }

  /**
   * Извлечь информацию о каналах ТУ.
   * @param reqBody объект, свойства которого используются для выборки данных
   * об анализе потребления ЮЛ.
   * @returns ответ сервера.
   */
  public fetchChannels(
    reqBody: ConsumptionAsReqBody
  ): Promise<{ result: boolean; data: [] }> {
    return this.handleAxiosResponse(
      this.axios.post("/channels", reqBody)
    );
  }


  /**
   * Извлечь перечень документов по определенному документу.
   * @param reqBody объект свойства которого используются для получения
   * перечня документов, доступного по определенному документу.
   * @returns ответ сервера.
   */
  public fetchSetOfDocumentsByContract(reqBody: SetOfDocumentsAsReqBody) {
    return this.axios.post("/set_of_documents", reqBody,
    );
  }

  /**
   * Отправить данные для получения ссылки на оплату
   * @param reqBody - объект с суммой и договором
   * @returns ответ сервера.
   */
  public getPaymentURL(reqBody: FormData) {
    return this.axios.post("/payment", reqBody, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  /**
   * Отправить данные для получения ссылки на оплату
   * @param reqBody - объект с суммой и договором
   * @returns ответ сервера.
   */
  public getPaymentNoAuthURL(reqBody: FormData) {
    return this.axios.post("/paymentNoAuth", reqBody, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  /**
   * Отправить данные для получения ссылки на оплату
   * @returns ответ сервера.
   */
  public getPaymentFee() {
    return this.axios.post("/paymentFee");
  }

  /**
   * Обработать причину отклонения запроса.
   * @param reason причина отклонения запроса.
   * @returns текст ошибки
   */
  public onReject(reason: AxiosError): string {
    const { isAxiosError, response } = reason;

    if (isAxiosError && response) {
      const { message, stackError = null } = response.data;

      return stackError || message;
    }

    return reason.message;
  }

  /**
   * Скачать шаблон документа, необходимый для регистрации
   *
   * @returns ответ сервера.
   */
  public downloadPatternRegistrationDocument() {
    return this.axios.post("/auth/get_pattern_doc", { download: true });
  }
}

const appApiURL =
  (process.env && process.env.VUE_APP_API_URL) ||
  "https://lkul-api.stack-it.ru/api";
/*(process.env && process.env.VUE_APP_API_URL) || "http://127.0.0.1:8000/api";*/
const appApi = new AppApi(appApiURL);

export default appApi;
