

























































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XForm from "../SimpleForm.vue";
import { contractAddingForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";
import { mapGetters } from "vuex";

const { values, attrs, validations, errors } = contractAddingForm;

@Component({
  components: { XForm },
  mixins: [validationMixin],
  // computed: errors,
  computed: {
    ...errors,
    ...mapGetters({
      email: "user/email",
    }),
  },
  validations,
})
class ContractAddingForm extends Mixins(AppApiMixin, XForm) {
  @Prop({ default: false }) readonly show!: boolean;

  values = values;
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  submitError = "";
  submitSuccess = "";

  @Watch("show")
  public showChanged() {
    if (!this.show) {
      this.resetValues();
      this.$v.$reset();

      this.submitError = "";
      this.submitSuccess = "";
    }
  }

  mounted() {
    this.values = {...this.values, email: this.userEmail, taxpayerId: this.orgInn};
    this.defaultValues = {...this.values, email: this.userEmail, taxpayerId: this.orgInn};
  }
  get userEmail() {
    return this.email
  }
  get orgInn() {
    return this.organizationInn
  }

  public submitForm() {
    this.submitError = "";
    this.submitSuccess = "";
    this.useSubmitButtonLoading = true;

    const { addContract, getValues } = this;

    const {
      email,
      contact: fio,
      phone: tel,
      taxpayerId: inn,
      contractList: text,
    } = getValues();

    addContract({ inn, fio, email, tel, text })
      .then((_) => {
        this.submitSuccess = "Заявка успешно отправлена";
      })
      .catch((e) => {
        this.submitError = e;
      })
      .finally(() => {
        this.useSubmitButtonLoading = false;
      });
  }
}

export default ContractAddingForm;
